import React from "react"
import { FooterWithCTA, SEO, Layout, Container } from "@components"
import ReactMarkdown from "react-markdown"
import { ExternalLink } from "@fragments"

import { Box } from "rebass"

const ChangelogPage = ({ pageContext: { releases } }) => {
  return (
    <>
      <Layout>
        <SEO
          title={`Dynobase's Changelog`}
          description={`Dynobase's Changelog - List of versions with features and bugfixes`}
        />

        <Container maxWidth={"960px"}>
          <h1
            style={{
              marginTop: "1.4em",
              fontSize: "2.5em",
            }}
          >
            Changelog
          </h1>
          <ExternalLink to="https://github.com/Dynobase/dynobase/releases">
            <p>[Follow new release on GitHub]</p>
          </ExternalLink>
          <Box mt={[32, 62]} sx={{ fontSize: "1.15em", lineHeight: 1.75 }}>
            {releases.map(release =>
              release.prerelease ? (
                <div key={release.name} />
              ) : (
                <div key={release.name}>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#777",
                    }}
                  >
                    {new Date(release.created_at).toLocaleDateString()}
                  </span>
                  <a
                    href={release.html_url}
                    style={{
                      textDecorationColor: "black",
                    }}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <h2 style={{ color: "black" }}>{release.name}</h2>
                  </a>

                  <ReactMarkdown>{release.body}</ReactMarkdown>
                </div>
              )
            )}
          </Box>
        </Container>

        <FooterWithCTA />
      </Layout>
    </>
  )
}

export default ChangelogPage
